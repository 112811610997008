exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-11-setting-up-tests-in-expo-app-using-jest-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2019-06-11-setting-up-tests-in-expo-app-using-jest/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-11-setting-up-tests-in-expo-app-using-jest-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-12-tired-of-constant-security-updates-to-your-ubuntu-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2019-06-12-tired-of-constant-security-updates-to-your-ubuntu/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-12-tired-of-constant-security-updates-to-your-ubuntu-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-16-making-a-simple-todo-mobile-app-react-native-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2019-06-16-making-a-simple-todo-mobile-app-react-native/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2019-06-16-making-a-simple-todo-mobile-app-react-native-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-02-29-how-to-maintain-device-specific-css-in-your-webapp-website-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2020-02-29-how-to-maintain-device-specific-css-in-your-webapp-website.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-02-29-how-to-maintain-device-specific-css-in-your-webapp-website-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-02-adding-navigation-to-your-react-native-app-using-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2020-08-02-adding-navigation-to-your-react-native-app-using.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-02-adding-navigation-to-your-react-native-app-using-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-19-optimizing-your-react-app-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2020-08-19-optimizing-your-react-app.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-19-optimizing-your-react-app-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-20-optimizing-your-react-app-ii-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2020-08-20-optimizing-your-react-app-ii.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2020-08-20-optimizing-your-react-app-ii-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2023-02-17-nextjs-mongoose-jest-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-02-17-nextjs-mongoose-jest/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-2023-02-17-nextjs-mongoose-jest-index-md" */)
}

